* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	color: #000
}

body {
	display: flex;
	width: 100%;
	height: 100%;
	overflow: hidden;
	justify-content: center;
	align-items: center;
}

.bg-wp1 {
	background-image: url("./images/wp1.jpg");
}

.bg-wp2 {
	background-image: url("./images/wp2.jpg");
}

.bg-wp3 {
	background-image: url("./images/wp3.jpg");
}

.bg-wp4 {
	background-image: url("./images/wp4.jpg");
}

.bg-wp5 {
	background-image: url("./images/wp5.jpg");
}

.bg-wp6 {
	background-image: url("./images/wp6.jpg");
}

.bg-wp7 {
	background-image: url("./images/wp7.jpg");
}

.bg-wp8 {
	background-image: url("./images/wp8.jpg");
}

.bg-wp9 {
	background-image: url("./images/wp9.jpg");
}

.bg-wp10 {
	background-image: url("./images/wp10.jpg");
}

.bg-wp11 {
	background-image: url("./images/wp11.jpg");
}

.bg-wp12 {
	background-image: url("./images/wp12.jpg");
}

.bg-container {
	margin: 0;
	padding: 0;
	width: 100vw;
	height: 100vh;
	background-size: cover;
	-webkit-background-size: cover;
	-moz-background-size: cover;
}

.menu {
	position: relative;
	width: 600px;
	height: 600px;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0.88;
}

.menu .toggle {
	position: absolute;
	width: 210px;
	height: 210px;
	background: #fff;
	color: #222327;
	border-radius: 70%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 7em;
	cursor: pointer;
	transition: 0.5s;
}

.menu .toggle.active {
	transform: rotate(315deg);
	box-shadow: 0 0 0 150px #fff;
	background: #222327;
	color: #fff;
}

.menu li {
	position: absolute;
	left: 370px;
	list-style: none;
	transform: rotate(calc(360deg / 8 * var(--i))) translateX(40px);
	transform-origin: 280px;
	visibility: hidden;
	opacity: 0;
	transition: 0.5s;
	z-index: 10;
}

.menu.active li {
	visibility: visible;
	opacity: 1;
}

.menu li a {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100px;
	height: 100px;
	font-size: 4em;
	color: #222327;
	transform: rotate(calc(360deg / -8 * var(--i)));
	border-radius: 50%;
}

.menu.active li.active {
	transform: rotate(calc(360deg / 8 * var(--i))) translateX(-25px);
}

.indicator {
	position: absolute;
	left: calc(50% - 5px);
	transform-origin: right;
	width: 275px;
	height: 10px;
	background: transparent;
	pointer-events: none;
	transition: 0.5s;
}

.indicator::before {
	opacity: 0;
	content: '';
	position: absolute;
	top: -58px;
	left: 211px;
	width: 120px;
	height: 120px;
	background: #222327;
	box-shadow: 0 0 0 6px #29fd53;
	border-radius: 50%;
	transition: 0.5S;
}

.menu.active .indicator::before {
	opacity: 0.8;
	top: -58px;
	left: -35px;
	background: #29fd53;
	box-shadow: 0 0 0 6px #222327;
}

.menu li:nth-child(2).active~.indicator {
	transform: translateX(-255px) rotate(0deg);
}

.menu li:nth-child(3).active~.indicator {
	transform: translateX(-255px) rotate(45deg);
}

.menu li:nth-child(4).active~.indicator {
	transform: translateX(-255px) rotate(90deg);
}

.menu li:nth-child(5).active~.indicator {
	transform: translateX(-255px) rotate(135deg);
}

.menu li:nth-child(6).active~.indicator {
	transform: translateX(-255px) rotate(180deg);
}

.menu li:nth-child(7).active~.indicator {
	transform: translateX(-255px) rotate(225deg);
}

.menu li:nth-child(8).active~.indicator {
	transform: translateX(-255px) rotate(270deg);
}

.menu li:nth-child(9).active~.indicator {
	transform: translateX(-255px) rotate(315deg);
}